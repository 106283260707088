$gap: 20px;
$fixContainerHeight: 120px;
$card-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);

.humanLegendColorBox {
    width: 20px;
    height: 20px;
    margin-right: 10px;

    &.first {
        background-color: #506AAC;
    }

    &.second {
        background-color: #7689BE;
    }

    &.third {
        background-color: #A5B0D0;
    }

    &.fourth {
        background-color: #E8E9EA;
    }

}

.DGHumanAllSector {
    flex: 0 0 calc(20% - 5px);
    box-sizing: border-box;
    margin-bottom: 10px;
}

.DGHumanTitleText {
    &.all {
        color: #ffffff;
    }
    &.both {
        color: #506AAC;
    }

    &.sme {
        color:#7689BE;
    }

    &.ke {
        color: #A5B0D0;
    }
}

.UnitBoxHuman {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    flex: 0 0 calc(33.33% - #{$gap});
    display: flex;

    border: 0px solid #BCBCBC;
    border-radius: 4px;
    box-shadow: $card-shadow;
    background-color: white;
    margin-right: $gap;
    margin-bottom: 40px;
}

.UnitSectorHuman {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    
    box-sizing: border-box;
    margin-bottom: 10px;
}

.issueContainerHumanDGAll{
    display: flex;   
}

.issueContainerHuman{
    display: flex;  
    margin-left: 10px; 
}

.issueBoxHumanDGAll{
    flex-basis: 33%;
}

.issueBoxHuman{
    flex-basis: 33%;
    border: 1px solid #BCBCBC;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
    margin-right: 4px;
    padding-left: 4px;
    padding-right: 4px;
}

.issueTextHuman{
    font-weight: 900;
}

