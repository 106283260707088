$card-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);

.verticalFixContainer {
    height: 390px !important;
}
    
.verticalFixWidth {
    width: 80%;
}

.DGAllWrapperFixed {
    display: flex;
    position: fixed;
    background-color: rgba(250, 250, 250, 0.95);
    flex-wrap: wrap;
    transition: height 200ms ease-in;
    //height: 230px;
    z-index: 1;

    &.minimized {
        height: 40px;
        transition: height 200ms ease-in;
    }
}

.minimizeHeaderFlap {
    height: 36px;
    width: 36px;
    background-color: white;
    box-shadow: $card-shadow;
    display: flex;
    justify-content: center;
    padding-top: 12px;
    cursor: pointer;
    user-select: none;
    margin-right: 10px;
    z-index: 1;
}

.DGMinimizedFixed {
    //height: 35px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-right: 60px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.DGHumanAllSectorVertical {
    height: 100%;
    flex-basis: 100% !important;
}

.DGAllBoxVertical {
    height: calc(100% - 5px);
}

.UnitWaterfallBackground {
    height: 100%;
}

.verticalBox{
flex-basis: calc(25% - 10px);
}


.gradientStop1 {
    stop-color: #F1f1f1;
    //stop-opacity: 0.502;
}

.gradientStop2 {
    stop-color: #bcbcbc;
}

.waterfall.labelText {
    font-weight: normal !important;
}