

/*Colors*/
$color-red: #EA3326;
$gap: 10px;
$fixContainerHeight: 120px;
$card-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);



.graphContainer {
    flex-grow: 1;
    padding: 0px 30px;
    overflow: auto;
    max-height: 100vh;
    padding-top: 0px;
    margin-top: 20px;
    margin-bottom: 25px;
}

.moduleContainer {
    padding: 10px;
}

.allUnitContainer {
    width: 100%;
}

.DGAllWrapper {
    width: 100%;
    display: flex;
}

.DGMaincontainer {
    height: 120px;
    width: calc(66% + $gap);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #BCBCBC;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    margin-bottom: 25px;
    margin-top: 20px;
    margin-left: 30px;
    padding-top: 40px;
    margin-right: $gap;
}

.DGAllBox {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.DGAllBoxTitle {
    position: relative;
    font-size: 23px;
    font-weight: 900;
    margin-top: -105px;
    margin-left: 0px;
    align-self: flex-start;
    flex-shrink: 0;
    margin-top: -60px;
    margin-bottom: 10px;
}

.DGAllBoxContent {
    flex: 1;
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
}

.DGAllSector {
    flex: 0 0 calc(25% - 10px);
    box-sizing: border-box;
    margin-bottom: 10px;
}

.DGLegendContainer {
    height: 120px;
    width: calc(33%);
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 4px;
    margin-right: 30px;
    padding-left: 10px;
    padding-top: 40px;
}

.legendTitle {
    font-weight: 800;
    margin-bottom: 20px;
}

.legendLines {
    display: flex;
    margin-bottom: 5px;
    white-space: nowrap;
}

.legendColorBox {
    width: 20px;
    height: 20px;
    margin-right: 10px;

    &.first {
        background-color: #85CC00;
    }

    &.second {
        background-color: #FFDD00;
    }

    &.third {
        background-color: #FFA100;
    }

    &.fourth {
        background-color: #0474FF;
    }

    &.fifth {
        background-color: #5BA3FD;
    }

    &.sixth {
        background-color: #B9D8FF;
    }
}


.legendText {
    font-weight: 800;
}

.unitWrapper {
    display: flex;
    flex-wrap: wrap;
    //margin-right: -$gap;
}

.UnitBox {

    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    flex: 0 0 calc(33.33% - #{$gap});
    height: 160px;
    display: flex;

    border-radius: 4px;
    box-shadow: $card-shadow;
    background-color: white;
    margin-right: $gap;
    margin-bottom: 40px;
    height: auto;

    &:nth-child(3n) {
        margin-right: 0;
    }
}

.UnitBoxTitle {
    position: relative;
    font-size: 23px;
    font-weight: 900;
    margin-top: -20px;
    margin-left: 10px;
    align-self: flex-start;
    flex-shrink: 0;
}

.UnitBoxScore {
    position: relative;
    display: flex;
    min-width: 50px;
    padding: 0px 10px;
    border-radius: 4px;
    background-color: white;
    margin-top: -27px;
    margin-right: 10px;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    flex-shrink: 0;
    height: 27px;
    box-shadow: $card-shadow;
}

.UnitBoxTrophy {
    position: relative;
    display: flex;
    margin-top: -27px;
    margin-right: 85px;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    flex-shrink: 0;
    background: white;
    border-radius: 4px;
    box-shadow: $card-shadow;
    height: 27px;
    width: 27px;

}

.UnitBoxContent {
    flex: 1;
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;

    &:nth-child(2n) {
        margin-right: 0;
    }
}

.UnitSector {
    flex: 0 0 calc(100%);
    height: auto;
    //border: 1px solid #000;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.UnitSectorTitle {
    display: flex;
    padding-left: 5px;
}

.UnitSectorTitleText {
    flex: 1;
    font-weight: 700;
}

.UnitSectorTitleProc {
    padding-right: 5px;
    font-weight: 700;
}

.UnitBarBackground {
    background-color: rgba(217, 217, 217, 0.5);
    //margin-left: 5px;
    //margin-right: 5px;
    height: 30px;
}

.UnitBarBackgroundDGAll {
    background-color: rgba(217, 217, 217, 0.5);
    //margin-left: 5px;
    //margin-right: 5px;
    //height: 60px;
}

.SectorWrapper {

    //margin-top: -100%;
    flex-shrink: 0;
    position: relative;
}

/*Tooltip unit compare page - tickets*/
.tooltip {
    position: absolute;
    background-color: #000000;
    border: 1px solid #000000;
    border-radius: 4px;
    padding: 10px;
    width: 240px;
    margin-left: 10px;
    height: auto;

    flex-shrink: 0;
    margin-top: 0%;
    z-index: 2000;
    top: 8px;

    .sum {
        color: white;
    }

    .first {
        color: #85CC00;
    }

    .second {
        color: #FFDD00;
    }

    .third {
        color: #FFA100;
    }
}

/*Tooltip - unit compare page - knowledge items bzw. Wissen - for dg all*/
.knowledgeitems {

    .first {
        color: #0474FF;
    }

    .second {
        color: #5BA3FD;
    }

    .third {
        color: #B9D8FF;
    }
}


.fullTextLine {

    display: flex;

    div {
        position: relative;
        border-right: 1px solid transparent;
        padding-right: 5px;
        padding-left: 2px;
    }

    div:last-child {
        border-right: none;
        padding-right: 0;
    }

    div:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 1px;
        background: linear-gradient(to bottom, transparent 0%, black 50%, transparent 100%); // Gradient mit deinen Angaben
    }

    .sum {
        color: black;
    }

    .first {
        color: #49CFAD;
    }

    .second {
        color: #FFDD00;
    }

    .third {
        color: #FFA100;
    }

}

.Kis {
    .sum {
        color: white;
    }

    .first {
        color: #0474FF;
    }

    .second {
        color: #5BA3FD;
    }

    .third {
        color: #B9D8FF;
    }
}

.tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #000000 transparent;
    flex-shrink: 0;
}

.tootipLine {
    width: 100%;
    display: flex;

}




.toolTiptext {
    width: 50%;
    text-wrap: nowrap;
}

.toolTipvalue {
    text-align: right;
    width: 50%;
}


//Human block

.DGAllWrapperBox {
    /*width: 110%;*/
    height: 185px;
    margin-bottom: 40px;
    transition: height 200ms ease-in;

    &.minimized {
        height: 40px !important;
        transition: height 200ms ease-in;
    }

}

.DGMainFixedcontainer {
    height: $fixContainerHeight;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: $card-shadow;
    background-color: white;
    flex-basis: 66%;
    flex-grow: 1;

}

.DGLegendFixContainer {
    height: auto;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0.2;
    margin-top: 30px;
}

.DGAllSeperator {
    border-top: 1px solid rgba(200, 200, 200, 1);
    width: 100%;
    height: 1px;
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;
    flex-grow: 1;
}

.DGAllWrapperBoxFix {
    height: 440px !important
}




.headerMenuIcon{display: none;}



.logo{
    display: block;
    height: 50px;
    width: 40px;
    margin-right: 10px;
   
}



@media screen and (max-width: 1200px) {
    .logo{display: none;}

    .DGMainFixedcontainer {
        margin: 30px 0px 30px 0px !important;
    }
    
    .headerMenuIcon{display:block;}

    .naviMightBeClosed, .moduleMightBeClosed {display: none;}

    .naviMightBeOpen{height: 100vh;}
    
    .UnitBox, .UnitBoxHuman {
        flex-basis: calc(50% - 10px);
        border-radius: 10px;
        border: 0px solid black;
    }

    .UnitBoxContent {
        background-color: transparent !important;
    }

    .DGMainFixedcontainer {
        border-radius: 10px;
    }

    .DGAllWrapperFixed {
        display: block;
        position: inherit;
        margin-right: 0px;
    }

    .DGAllWrapperBox {
        height: auto;
    }

    .DGLegendFixContainer {
        padding-left: 0px;
        padding-bottom: 20px;
    }

    .DGMinimizedFixed {
        padding-right: 60px;
    }

    .DGAllWrapperFixed.minimized,
    .DGAllWrapperBox.minimized {
        margin-bottom: 4%;

    }
    .DGAllBoxTitle{
    margin-top: -30px;}

    .DGMinimizedFixed {
        height: 24px;
    }
    
    .DGMainFixedcontainer {
        height: auto !important;
        padding: 10px;
    }
}


@media screen and (max-width: 800px) {
    .hideonphone{display: none !important;}
    
    .DGAllBoxTitle {
        margin-top: 0px !important;
    }

    .DGAllSector, .DGHumanAllSector {
        flex: 0 0 calc(100% - 0px) !important;
        margin-bottom: 10px !important;
    }
    
    .UnitBox, .UnitBoxHuman{
        flex-basis: calc(100% - 0px);
        border-radius: 10px;
        padding: 10px;
        border: 0px solid red;
        margin-bottom: 30px;
    }

    .DGMainFixedcontainer {
        justify-content: space-evenly;
        
    }

    .allUnitContainer {
        width: 100%;
        margin-top: 30px;
    }

    .UnitBoxScore {
        margin-top: -60px;
    }

    .UnitBoxContent {
        padding-top: 50px;
    }

    .UnitBoxContent.UnitBoxContent {
        padding-top: 40px;
    }

    .UnitBarBackgroundDGAll,
    .UnitBarBackground,
    .unitcompare.knowledgeBar {
        height: 20px !important;
        box-shadow: none !important;
    }

    

    .DGAllWrapperBox {
        height: auto;
    }
    .unitWrapper {
        margin-top: 30px;
    }
   
    .graphContainer{
        padding-top: 0px;
        margin-top: 0px;
        padding: 0px 20px;
    }
    .UnitBoxTitle {
        margin-top: 0px;
    }
    .headerUserName {
        display:none;
    }

}   