.overWriteDGAllSector {
    flex-basis: calc(100% - 10px) !important;
}

.HistoryBarBackgroundDGAll,
.HistoryBarBackground {
    height: 200px;
    box-shadow: 0.828125px -16px 45px -50px black inset;
}

.DGMainFixedcontainerHistoryFix {
    height: 250px !important;
}

.historyBlock {

    &.tasks {
        &.first {
            fill: #FFA100;
        }
        
        &.second {
            fill: #FFDD00;
        }
        
        &.third {
            fill: #85CC00;
        }
        
        &.fourth {
            fill: #CCCCCC;
        }
    }

    &.kis {
        &.first {
            fill: #B9D8FF;
        }
        
        &.second {
            fill: #5BA3FD;
        }
        
        &.third {
            fill: #0474FF;
        }
    }
}

.tooltipbox{
    fill: #3f3f3f
}

.tooltiptext {
    fill: white;
    font-size: 12px;
    //text-anchor: middle;
}