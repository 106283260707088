$flap-width: 20px;
$right-flap: -$flap-width;
$navi-width: 260px;
$card-shadow-modal: 3px 4px 50px rgba(0, 0, 0, 0.28);

/*Fonts*/
$font-weight-bold: 700;
$font-weight-light: 300;
$font-color-black: #4a4a4a;

/*Colors*/
$color-red: #EA3326;

.infoTrigger {
    font-weight: 400;
    cursor: pointer;
}

.infoTriggerIcon {
    pointer-events: none;

    &.default {
        //Grunddisplay
        font-weight: 400;
    }

    &.hover {
        font-weight: 400;
        color: $color-red;
    }

    &.active {
        color: $color-red;
    }
}

$initialWidth: 630px;
$initialHeight: 400px;

//InfoWindow

.infoWindow {
    position: fixed;
    top: 300px;
    left: 50%;
    margin-left: calc($initialWidth / -2);
    z-Index: 1000;
    background-color: white;
    resize: both;
    width: $initialWidth;
    height: $initialHeight;
    max-width: 100%;
    max-height: 100%;
    border: 0px solid black;
    
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: $card-shadow-modal;
    
}

.infoWindowHeader{
    background-color: $color-red    ;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
}

.dragIcon{
    color: white;
}

.closeHandler{
    color: white;
    cursor: pointer;
}

.infoWindowContent{
    padding: 0px 20px 20px 20px;
    flex-grow: 1;
    overflow-y: auto;
}


@media screen and (max-width: 1200px) {
    .infoWindow {
        position:absolute;
        top: 50px;
        left: 0%;
        margin: 30px;
        z-Index: 1000;
        background-color: white;
        resize:none;
        width: 90%;
        height: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        box-shadow: $card-shadow-modal;
        
    }
}