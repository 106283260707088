$color-red: #EA3326;
$box-shadow:0 4px 0 -42px rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


.unitcompare {

    &.ticketBar {
        height: 30px;

        &.first {
            fill: #85CC00
        }

        &.second {
            fill: #FFDD00
        }

        &.third {
            fill: #FFA100
        }

        &.fourth {
            fill: #CCCCCC
        }

        
        
    }
    &.knowledgeBar {
        height: 30px;
        
        
        &.first {
            fill: #0474FF
        }

        &.second {
            fill: #5BA3FD
        }

        &.third {
            fill: #B9D8FF
        }

    }

    &.automation {
        height: 30px;

        &.shadow {
            fill: rgba(12, 12, 12, 1);
            opacity: 0.5;
        }

        &.standard {
            fill: #b5b5b5;
            opacity: 0.5;
        }

        &.standardShadow {
            box-shadow: 0.828125px -16px 45px -50px black inset;
            //fill: #d0d0d0;
            //opacity: 0.5;
        }

        &.meanLine {
            //stroke: #e8e9ea;
            stroke: $color-red;
            stroke-width: 2;
        }

        &.DGUnit {
            fill: #b5b5b5
        }

    }

    &.reuse {
        height: 30px;
        fill: #e8e9ea
    }

}

.waterfall {
    &.modalbackground {
        fill: rgba(255, 255, 255, 0.5)
    }

    &.knowledgeBar {
        height: 30px;
        fill: #44a7a6
    }

    &.labelText {
        font-size: 15px;
        font-weight: 600;
    }

    &.valueText {
        font-size: 15px;
        font-weight: 600;
        text-anchor: end;
    }

    &.connector {
        &.background {
            fill: url(#bg-gradient);
        }

        &.insufficient {
            fill: #FFAFAF;

        }

        &.labelText {
            font-size: 15px;
            font-weight: 600;
        }
    }
}

.knowledge {

    height: 30px;

    &.shadow {
        fill: rgba(12, 12, 12, 1);
        opacity: 0.5;
    }

    &.standard {
        fill: #e8e9ea;
        opacity: 0.5;
    }

    &.standardShadow {
        fill: #d0d0d0;
        opacity: 0.5;
    }

    &.meanLine {
        //stroke: #e8e9ea;
        stroke: $color-red;
        stroke-width: 2;
    }

    &.DGUnit {
        fill: #e8e9ea
    }

    &.svgUserRect {
        &.sum {
            fill: #7a3e7a;
        }

        &.sme {
            fill: #15ac6d;
        }

        &.ke {
            fill: #3BAFDA;
        }

        fill: #FBCB52;
    }

}

.human {
    height: 30px;

    &.shadow {
        fill: rgba(12, 12, 12, 1);
        opacity: 0.5;
    }

    &.standard {
        fill: #b5b5b5;
        opacity: 0.5;
    }

    &.standardShadow {
        fill: #d0d0d0;
        opacity: 0.5;
    }

    &.meanLine {
        //stroke: #e8e9ea;
        stroke: $color-red;
        stroke-width: 2;
    }

    &.DGUnit {
        fill: #e8e9ea
    }

    &.svgUserRect {
        &.sum {
            fill: #506AAC;
        }

        &.sme {
            fill: #7689BE;
        }

        &.ke {
            fill: #A5B0D0;
        }
        //active users
        fill: #E8E9EA   ;
    }

}

.automat {

    &.ticketBar {
        height: 30px;

        &.first {
            fill: #85cc00
        }

        &.second {
            fill: #fd0
        }

        &.third {
            fill: #ffa100
        }
    }

    &.circleBackground{
        fill: #e0e0e0;
        opacity: 0.2;
    }

    &.circleShadow{
        fill: #fff;    
    }
    
   
}

