$flap-width: 20px;
$right-flap: -$flap-width;
$navi-width: 260px;
$card-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);

/*Fonts*/
$font-weight-bold: 700;
$font-weight-light: 300;
$font-color-black: #4a4a4a;

/*Colors*/
$color-red: #EA3326;


.headerContainer {
    width: 100%;
    height: 50px;
    background-color: white;

    justify-content: space-between;
    align-items: center;
}


.headerContainer {
    display: flex;
}


.headerMenuIcon {
    height: 100%;
    width: 50px;
    padding-top: 4px;
}

.headerMenuIconInner {
    margin-top: 1px;
    margin-left: -1px;
}

.headerMenuBox {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 0px;

    background-color: white;
    border-left: 1px solid #DEDEDE;
    border-right: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2), -3px 3px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.headerBoxLinkWrapper {
    padding-left: 5px;
}

.HeaderBoxLink {
    padding: 5px;
    margin-top: 3px;
    cursor: pointer;
}

.HeaderBoxLink:hover {
    background-color: #8B8B8B;
}

.headerTitle {
    flex-grow: 1;
    height: 100%;
    text-align: left;
    align-items: center;
    display: flex;
    font-weight: $font-weight-light;
}
.headerUserBlock {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    z-index: 200;
    cursor: pointer;
    padding: 10px;

}

.headerUserName {
    color: $font-color-black;
    z-index: 220;
    padding: 0px;
    padding-right: 10px;
}


.headerUserBlock:hover {
    background-color: $color-red;
    z-index: 220;

    .headerUserName {
        color: white;
    }

    .headerUserShodowkiller {
        background-color: $color-red;
    }
}

.headerUserAvatar {
    padding-right: 5px;
    padding-top: 1px;
    border-radius: 50%;
    margin-right: 5px;
    width: 36px;
    height: 36px;
    border: 2px solid #DEDEDE;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .4);
    overflow: hidden;

    img {
        margin-left: -2px;
        ;
    }
}

.headerDetails {
    position: absolute;
    top: 50px;
    transform: translateX(-10px);
    background-color: white;
    border-left: 1px solid #DEDEDE;
    border-right: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
    z-index: 190;
}

.headerDetailsLink {
    padding: 10px;
    padding-left: 30px;
    text-align: left;
}

.headerDetailsLink:hover {
    background-color: #EAEAEA;
}

.headerDetailTopLine {
    display: flex;

    .headerOrgTitle {
        padding: 0px;
        flex-grow: 1;
    }

    .headerLogout {
        text-align: right;
        padding: 0px;
    }

    .headerLogout:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.headerDetailBottomLine {
    display: flex;

    .headerDetailAvatar {
        height: 100px;
        width: 100px;

    }

    .headerDetailInfo {
        margin-left: 30px;

        .headerDetailInfoName {
            font-size: larger;
            font-weight: bolder;
        }
    }
}

.DGMainFixedcontainer {
    margin: 30px 20px 30px 0px;
    height: 120px;
    padding: 10px;
}

.legendBox {
    width: auto;
    
    ;
}
.legendBox:nth-child(2) {
    margin-left: 20px;
  }

.legendText {
    font-weight: $font-weight-light;
}

.unitWrapper {
    margin-right: 0px;
    padding-bottom: 50px;
}

.DGAllSeperator {
    border-top: 1px solid #D9D9D9;
}



.UnitSectorTitle {
    padding-left: 0px;
}
.UnitSectorTitleText {
    font-weight: 400;
}
.UnitSectorTitleProc {
    padding-right: 0px;
}


.unitButtonSelected {
    background-color: $color-red;
}

.UnitBarBackgroundDGAll, .UnitBarBackground {
    height: 30px;
    box-shadow: 0.828125px -16px 45px -50px black inset;
}
.DGAllSector, .DGHumanAllSector {
    margin-bottom: 0px;
    }
.DGAllBoxContent{
    padding: 0px;
}
.headerDetails {
    box-shadow: $card-shadow;
    width: auto;
    right: 0px;
    padding: 10px 10px 20px 10px;
}
.headerLogout:hover {
    text-decoration: underline;
}
.headerUserAvatar {
    border: 0px solid #dedede;
    border-radius: 50%;
    box-shadow: none;
    height: 36px;
    margin-right: 5px;
    overflow: hidden;
    padding-right: 0px; 
    padding-top: 0px;
    width: 36px;
}
.headerUserAvatar img {
    margin-left: 0px;
}
.headerDetailBottomLine .headerDetailAvatar {
    border: 0px solid #dedede;
    box-shadow: none;
    padding: 0px;
}
.headerDetailBottomLine .headerDetailAvatar img {
    margin-left: 0px;
}
.headerDetailBottomLine {
    display: flex;
    margin-top: 20px;
}

.burger-icon {
    width: 25px;
    height: 20px;
    transition: transform 0.3s;
    margin: 10px;
    position: relative;

    //OVERWRITE THE FOLLOWING RESPONSIVELY!

    cursor: pointer;    
    //display: none;
}

.burger-icon .line1,
.burger-icon .line2,
.burger-icon .line3 {
    background-color: #333;
    height: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    transition: transform 0.3s, opacity 0.3s;
}

.burger-icon .line1 {
    top: 0;
}

.burger-icon .line2 {
    top: 50%;
    transform: translateY(-50%);
}

.burger-icon .line3 {
    top: 100%;
    transform: translateY(-100%);
}

.burger-icon.open .line1 {
    transform: rotate(45deg) translate(8px, 9px);
}

.burger-icon.open .line2 {
    opacity: 0;
}

.burger-icon.open .line3 {
    transform: rotate(-45deg) translate(5px, -6px);
}