$flap-width: 20px;
$right-flap: -$flap-width;
$navi-width: 230px;

/*Fonts*/
$font-weight-bold: 700;
$font-weight-light: 300;
$font-color-black: #4a4a4a;

$card-shadow: 2px 0px 6px rgba(0, 0, 0, 0.16);



.naviWrapper {
    overflow: hidden;
    white-space: nowrap;
    //border-right: 0px solid rgba(200, 200, 200, 0.9);
    background-color: #f5f5f5;
    border-style: solid;
    border-image:
        linear-gradient(to bottom,
            rgb(217, 217, 217),
            rgba(0, 0, 0, 0)) 1 100%;
    border-width: 1px;
}

.naviMightBeOpen{
    position: absolute;
    left: 40px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(30px);
    z-index: 90000;
}

.naviMax {
    padding: 15px 20px;
    width: $navi-width;
    min-width: 230px;
    transition: width 0.5s ease;
}

.naviMin {
    width: 0px;
    transition: width 0.5s ease;
}

.flap {
    width: $flap-width;
    height: 40px;
    position: fixed;
    top: 50vh;
    transform: translateY(-50%);
    border-radius: 0 5px 5px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2px;
    box-shadow: $card-shadow;
    background-color: #fff;
    color: rgba(100, 100, 100, 0.7);
    z-index: 1;

}

.flap:hover {
    background-color: rgba(250, 250, 250, 1);
    cursor: pointer;
}

.flapMin {
    left: 8px;
    border: 0px solid #d9d9d8;
}


.naviContainer {
    overflow: hidden;
    white-space: nowrap;
}

.timespanContainer {
    padding-bottom: 30px;
}

.timespanContainer div {
    font-weight: $font-weight-bold;
}


.UnitButtonWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
}

.UnitButtonWrapperText {
    white-space: nowrap;
    font-weight: $font-weight-bold;
}

.tbe {
    padding: 3px;
}

.unitButton {
    justify-content: left !important;
    min-width: 1px !important;
    padding: 4px !important;

    border-radius: 10px !important;
    height: 25px !important;

    background-color: white !important;
    color: $font-color-black !important;
}

.unitButtonSelected,
.unitButton:hover, .downloadButton:hover {
    color: white !important;
    background-color: red !important;
}

.unitButtonText {
    padding-left: 0px;
}

.tooltip-button {
    position: relative;
    cursor: pointer;
}

.unittooltip {
    position: absolute;

    margin-top: 10px;
    white-space: nowrap;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.moduleSelectorBox {
    width: 50px;
    min-width: 50px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: rgba(232, 233, 234, 1);
}

.moduleMightBeOpen {
    position: absolute;
    height: 100%;
    
    
    backdrop-filter: blur(8px);
    z-index: 90001;
}

.moduleSelector {
    display: flex;
    width: 50px;
    height: 50px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.selectedModule {

    border-left: 2px solid #ea3326;
}

.selectedIcon path {
    fill: #ea3326;
}

.selectableIcon path {
    fill: #8b8b8b;
}
    
.selectableIcon:hover path {
    fill: #ea3326;
}

.selectableModule {
    cursor: pointer;
}

.moduleDescriptor {
    position: absolute;
    left: 50px;
    background-color: rgba(0, 0, 0, 0.9);
    border: 0px solid white;
    color: white;
    padding: 5px;
    border-radius: 4px;
    display: none;
    width: max-content;
    box-shadow: 0 0 2px #00000029;
}

.moduleSelector:hover .moduleDescriptor {
    display: block;
}

.timeSelector {
    width: 100%;
    margin-top: 5px;
    color: $font-color-black;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    outline: 0px;
    height: 30px;
    font-size: 14px;
}

.UnitButtonSeperator {
    height: 20px;
}

// Historien Overwrite and define
.switch {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 30px;
    
    user-select: none;
    cursor: pointer;
    font-weight: $font-weight-light;
    border: 0px solid #d1d1d1;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    transition: .4s;
    border-radius: 5px;
    border: 1px solid #d1d1d1;
}

.slider:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 50%;
    left: 0px;
    bottom: 0px;
    background-color: red;
    
    transition: .4s;
    border-radius: 5px;
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: -19px;
    display: flex;
    
}

.bg-left,
.bg-right {
    flex: 1;
}

.bg-left {
    position: absolute;
        width: 50%;
        left: 0%;
        bottom: 10px;
        top: 3px;
        text-align: center;
       
}

.bg-right {
    position: absolute;
    width: 50%;
    left: calc(50% - 3px);
    bottom: 10px;
    top: 3px;
    text-align: center;
    
}

.handle-text {
    position: absolute;
    width: 50%;
    left: 0;
    bottom: 10px;
    top: 5px;
    text-align: center;
    color: white;
}

input:checked+.slider:before {
    transform: translateX(calc(100% - 3px));
}

input:checked+.slider .handle-text {
    transform: translateX(calc(100% - 3px));
    /* Stellt sicher, dass der Text mit dem Handle mitbewegt wird */
}

.downloadContainer{
    padding-top: 15px;
    
}

.downloadButton {
    justify-content: center !important;
    min-width: 1px !important;

    border-radius: 10px !important;
    height: 30px !important;

    background-color: white !important;
    color: $font-color-black !important;
}

