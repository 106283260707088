html{
    font-family: var(--fontFamilyBase, "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, 'Titillium Web', 'Helvetica Neue', 'sans-serif');
    font-weight:var(--fontWeightSemibold, 600);
    font-size: 16px;
    font-weight: 300;

}

body{
    overflow: hidden;
}

.mainContainer {
    display: flex;
    position: relative;
    height: 100vh;
    background-color: rgba(230,230,230,0.3);
    
}