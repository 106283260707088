$box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);


.DGAllBigSector {

    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33% - 5px);
    box-sizing: border-box;
    align-self: stretch;
}

.autoWrapper{
    height: 370px !important;
}

.AutoUnitSector{
    flex-basis: 100% !important;
    height: auto !important;
}

.AutoUnitBoxContent{
    height: 750px !important;
}

.AutoUnitBox{
    height: 750px !important;
}

.automationHeight {
    height: 300px !important;
}

.automationCircleTitleText {
    flex: none !important;
    margin-right: 2px;
}

.DGAllTicketMasterBox {
    margin: 0px;
}


.DGAllAutomationRateContainer {
    margin-top: 10px;
}

.AutomationCircleSector {
    display: flex;
}

.AutomationCircleRateBox {
    margin-right: 8px;
    flex-basis: calc(33% - 0px);
}

.AutomationCircleBackground {
    width: 120px;
    height: 120px;
    margin-right: 8px;
}

.rateCircle {

    &.full {
        fill: rgba(133, 204, 0, 1)
    }

    &.part {
        fill: rgba(255, 221, 0, 1)
    }

    &.all {
        fill: rgba(36, 88, 86, 1)
    }
}

// Bars 

.DGALLBarBack {

    margin-left: 5px;
    margin-right: 5px;
    height: 60px;
}

.leftBarBackground {
    fill: #e8e9ea;
}

.rightBarBackground {
    fill: #577f95;
}

.leftBarValue {
    fill: #cccfd1
}

.rightBarValue {
    fill: #4a7288
}

.BarLabelBox {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    top: -60px;
}

.leftTextContainer {
    flex: 0.5 1;

    background-color: white;
    box-shadow: $box-shadow;
    color: black;
    padding: 20px;


    .labeltext {
        font-weight: 400;
    }

    .valuetext {
        font-weight: 700;
    }
}

.rightTextContainer {
    flex: 0.5 1;

    background-color: white;
    box-shadow: $box-shadow;
    color: black;
    padding: 20px;
    .labeltext {
        font-weight: 400;
    }

    .valuetext {
        font-weight: 700;
    }
}

.BarBox {
    margin-bottom: 3px;
    height: 60px;
}


.gaugeMasterContainer {
    height: 100%;
}

.DGGaugeContainer {

    height: 100%
}

.DGALLGaugeBack {
    height: 250px;
}

.arcSVG {


    .arcBackground {
        fill: #d9d9d9;
        opacity: 0.5;
        
    }

    .arcValue {
        fill: #245856;
    }

    .arcStdValue {
        stroke: white;
        fill: none;
        
    }

    .tick {
        fill: #fff;
        stroke: #d9d9d9;
        stroke-miterlimit: 10;
        stroke-width: 2;
    }

    .label {
        text-anchor: middle;
        font-size: 10px;
    }
}

.DGALLGaugeCenterText {
    position: relative;
    margin-top: -120px;
    
    left: 50%;
    text-anchor: middle;
    transform: translate(-50%, 50%);
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 36px;
}

.DGALLGaugeCenterTextLabel{
    position: relative;
        margin-top: 20px;
    
        left: 50%;
        text-anchor: middle;
        transform: translate(-50%, 50%);
    
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 15px;
}       

.legendBox .Box{
    margin-top: 20px;
}
.legendBox .Box div{
    margin-bottom: 10px;
}