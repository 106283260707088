.infoContainerScore{
    position: relative;
    display:none;
    margin-top: -25px;
    margin-right: -20px;
    background-color: white;
    width: 20px;
    height: 15px;
    border-radius: 14px;

    span {
        
        height: 14px;
        display: block;

        svg{
            display:block;
        }
    }
}

.infoContainerScore, .UnitBoxContent .UnitSectorTitleText .infoTrigger{
    display:none; //Hide infoBubbles
}

.infoContainerScore.element_1{
    display:block; //Show infoBubble for first element
}